import React from 'react'

import MarkdownContent from 'components/common/atoms/MarkdownContent'
import Dotdotdot from 'react-dotdotdot'
import { css } from '@emotion/core'

type EllipsisProps = {
  text: string
  clamp?: number
  color?: string
  useMarkdown?: boolean
  className?: string
  noMargin?: boolean
}

const Ellipsis = ({ text, clamp = 5, color, useMarkdown = false, className = '', noMargin = false }: EllipsisProps) => {
  const useNativeClamp = true
  return (
    <Dotdotdot
      className={`ellipsis ${className}`}
      clamp={clamp}
      useNativeClamp={useNativeClamp}
      css={css`
        color: ${color || 'inherit'};
        font-size: inherit;
        line-height: inherit;
        font-weight: inherit;
        * {
          color: ${color || 'inherit'};
          font-size: inherit;
          line-height: inherit;
          font-weight: inherit;
        }
      `}
    >
      {useMarkdown ? <MarkdownContent content={text} className={`${className}-markdown`} noMargin={noMargin} /> : text}
    </Dotdotdot>
  )
}

export default Ellipsis
