import React, { FC } from 'react'

import { css } from '@emotion/core'
import ContentLoader from 'react-content-loader'
import { useTheme } from 'emotion-theming'

interface LoaderSize {
  width: number
  height: number
  borderRadius: number
}

type LoaderProps = {
  dataTestId?: string
  className?: string
  width?: 'inherit'
  widthPC?: 'inherit'
  height?: 'inherit' | number
  customSize?: {
    sp: LoaderSize
    pc: LoaderSize
  }
  type?: 'title' | 'text' | 'card' | 'smallCard' | 'carousel' | 'smallBanner' | 'banner' | 'directoryCard'
}

const loaderStyle = (
  width: LoaderProps['width'],
  height: LoaderProps['height'],
  widthPC: LoaderProps['widthPC']
) => css`
  .loader {
    &-pc,
    &-sp {
      width: ${width || '100%'};
      height: ${height || '100%'};
    }
    &-pc {
      display: none;
    }
    @media screen and (min-width: 768px) {
      &-sp {
        display: none;
      }
      &-pc {
        display: block;
        width: ${widthPC || width || '100%'};
      }
    }
  }
`

const sizesByType = (type: LoaderProps['type']) => {
  switch (type) {
    case 'carousel':
      return {
        sp: {
          width: 360,
          height: 188,
          borderRadius: 0,
        },
        pc: {
          width: 1190,
          height: 200,
          borderRadius: 10,
        },
      }
    case 'banner':
      return {
        sp: {
          width: 360,
          height: 188,
          borderRadius: 0,
        },
        pc: {
          width: 576,
          height: 188,
          borderRadius: 10,
        },
      }
    case 'smallBanner':
      return {
        sp: {
          width: 360,
          height: 188,
          borderRadius: 0,
        },
        pc: {
          width: 386,
          height: 192,
          borderRadius: 10,
        },
      }
    case 'smallCard':
      return {
        sp: {
          width: 150,
          height: 170,
          borderRadius: 10,
        },
        pc: {
          width: 225,
          height: 250,
          borderRadius: 10,
        },
      }
    case 'directoryCard':
      return {
        sp: {
          width: 158,
          height: 240,
          borderRadius: 10,
        },
        pc: {
          width: 225,
          height: 313,
          borderRadius: 10,
        },
      }
    case 'card':
      return {
        sp: {
          width: 150,
          height: 260,
          borderRadius: 10,
        },
        pc: {
          width: 225,
          height: 313,
          borderRadius: 10,
        },
      }
    case 'title':
      return {
        sp: {
          width: 360,
          height: 26,
          borderRadius: 10,
        },
        pc: {
          width: 1190,
          height: 30,
          borderRadius: 10,
        },
      }
    case 'text':
      return {
        sp: {
          width: 360,
          height: 8,
          borderRadius: 4,
        },
        pc: {
          width: 1190,
          height: 16,
          borderRadius: 12,
        },
      }
    default:
      return {
        sp: {
          width: 64,
          height: 30,
          borderRadius: 4,
        },
        pc: {
          width: 177,
          height: 44,
          borderRadius: 10,
        },
      }
  }
}

const Loader: FC<LoaderProps> = ({ dataTestId = '', type, customSize, width, height, widthPC, ...props }) => {
  const theme: ThemeType = useTheme()
  const sizes = customSize || sizesByType(type)
  return (
    <div className="loader" css={loaderStyle(width, height, widthPC)} data-testid={dataTestId} {...props}>
      <ContentLoader
        speed={1.2}
        width={sizes.pc.width}
        height={sizes.pc.height}
        viewBox={`0 0 ${sizes.pc.width} ${sizes.pc.height}`}
        backgroundColor={theme.gray.gray20}
        foregroundColor={theme.gray.gray10}
        className="loader-pc"
        data-testid={`${dataTestId}-pc`}
      >
        <rect
          x="0"
          y="0"
          rx={sizes.pc.borderRadius}
          ry={sizes.pc.borderRadius}
          width={sizes.pc.width}
          height={sizes.pc.height}
        />
      </ContentLoader>
      <ContentLoader
        speed={1.2}
        width={sizes.sp.width}
        height={sizes.sp.height}
        viewBox={`0 0 ${sizes.sp.width} ${sizes.sp.height}`}
        backgroundColor={theme.gray.gray20}
        foregroundColor={theme.gray.gray10}
        className="loader-sp"
        data-testid={`${dataTestId}-sp`}
      >
        <rect
          x="0"
          y="0"
          rx={sizes.sp.borderRadius}
          ry={sizes.sp.borderRadius}
          width={sizes.sp.width}
          height={sizes.sp.height}
        />
      </ContentLoader>
    </div>
  )
}

export default Loader
