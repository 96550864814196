import gql from 'graphql-tag'

export const GET_PROMOTION_BANNER = gql`
  {
    homepage {
      promotionBannerDesktopAlt
      promotionBannerMobileAlt
      promotionBannerDesktop {
        url(size: "1880x474")
      }
      promotionBannerMobile {
        url(size: "680x536")
      }
      action
      url
    }
  }
`
