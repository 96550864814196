import React from 'react'
import { NextSeo } from 'next-seo'

const defaultSeo = {
  title: 'Quipper Kampus - Info Kampus Terlengkap dan Terbaik di Indonesia',
  description:
    'Dapatkan info kampus, universitas dan perguruan tinggi terbaik di Indonesia lengkap dengan jurusan dan prospek karier!',
  keywords: '',
  canonical: 'https://campus.quipper.com/',
  robots: 'noindex,nofollow',
  og: {
    title: 'Quipper Kampus - Info Kampus Terlengkap dan Terbaik di Indonesia',
    description:
      'Dapatkan info kampus, universitas dan perguruan tinggi terbaik di Indonesia lengkap dengan jurusan dan prospek karier!',
    url: 'https://campus.quipper.com/',
    // eslint-disable-next-line @typescript-eslint/camelcase
    site_name: 'Quipper Campus',
    locale: 'id_ID',
    type: 'website',
  },
  twitter: {
    card: 'summary',
    site: '@quipper_id',
    creator: '@quipper_id',
  },
}

const CampusSeo = ({ seo }: { seo: LooseObj }) => {
  return (
    <NextSeo
      title={seo.title || defaultSeo.title}
      description={seo.description || defaultSeo.description}
      openGraph={seo.og || defaultSeo.og}
      twitter={seo.twitter || defaultSeo.twitter}
      canonical={seo.canonical || defaultSeo.canonical}
    />
  )
}

export default CampusSeo
