import React from 'react'
import { css } from '@emotion/core'

import { GET_PROMOTION_BANNER } from '@graphql/queries/promotionBanner'
import { useQuery } from '@apollo/react-hooks'
import Relazsizes from 'relazsizes'
import { triggerEvent } from 'utils/dataLayer'

import Container from 'components/layouts/atoms/Container'
import Loader from 'components/common/atoms/Loader'

const PromotionBanner = () => {
  const { data, loading } = useQuery(GET_PROMOTION_BANNER)

  const promotionBanner = data?.homepage

  if (loading) {
    return (
      <Container isMobileFull>
        <Loader type="carousel" dataTestId="promotion-banner-skeleton" />
      </Container>
    )
  } else if (promotionBanner?.promotionBannerDesktop?.url) {
    const link =
      promotionBanner?.action === 'brochure' ? '/promotion_banner/brochure' : promotionBanner?.url || '/aptitude_test'
    const alt = promotionBanner?.promotionBannerMobileAlt || promotionBanner?.promotionBannerDesktopAlt
    return (
      <div className="promotion-banner">
        <Container isMobileFull>
          <a href={link} onClick={() => triggerEvent('home_big_banner', { click_url: link, banner_name: alt })}>
            <Relazsizes
              alt={alt}
              el="picture"
              src="next-assets/images/skeleton.svg"
              dataSrcset={[
                {
                  media: '(min-width: 576px)',
                  srcset: promotionBanner?.promotionBannerDesktop?.url,
                  alt: promotionBanner?.promotionBannerDesktopAlt || promotionBanner?.promotionBannerMobileAlt,
                },
              ]}
              dataSrc={promotionBanner?.promotionBannerMobile?.url || promotionBanner?.promotionBannerDesktop?.url}
              css={css`
                width: 100%;
                @media screen {
                  object-fit: cover;
                  max-height: 220px;
                }
              `}
            />
          </a>
        </Container>
      </div>
    )
  }
  return null
}

export default PromotionBanner
